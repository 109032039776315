import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I am a general intelligence living in London. `}</p>
    <p>{`I moved to China at 18 years old, and haven't stopped exploring ever since. `}</p>
    <p>{`Currently playing with Machine Learning at Facebook.  `}</p>
    <p>{`Find me at `}<strong parentName="p"><a parentName="strong" {...{
          "href": "mailto:hello@lessand.ro"
        }}>{`hello@lessand.ro`}</a></strong>{` and `}<a parentName="p" {...{
        "href": "https://twitter.com/lessand_ro"
      }}>{`twitter.com/lessand_ro`}</a></p>
    <p><a parentName="p" {...{
        "href": "/tags/programming"
      }}>{`writing (code)`}</a>{` . `}<a parentName="p" {...{
        "href": "/building-products"
      }}>{`building products`}</a>{` . `}<a parentName="p" {...{
        "href": "/speaking-mandarin"
      }}>{`speaking mandarin`}</a>{` . `}<a parentName="p" {...{
        "href": "/playing-music"
      }}>{`playing music`}</a>{` . `}<a parentName="p" {...{
        "href": "/meditation"
      }}>{`meditation`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      